import {PathType, CookieData, ValidationResult, PointType} from './interfaces'

const cookieRoot = 'connectle-v2_level-'

// Simple encryption (Base64 encoding)
function encryptData(data: CookieData) {
  // Convert the data to a string and then to Base64 encoding
  return btoa(JSON.stringify(data)); // `btoa` encodes the string in Base64
}

// Simple decryption (Base64 decoding)
function decryptData(encryptedData: string) {
  // Decode the Base64 data and parse the JSON string
  return JSON.parse(atob(encryptedData)); // `atob` decodes the Base64 to a string
}


export function doesCookieExist(level: number): boolean {
    const cookieName = cookieRoot + level + "="; // Construct the cookie name
    const cookies = document.cookie.split("; "); // Split all cookies into an array
    return cookies.some(cookie => cookie.startsWith(cookieName));
}


export function storePathsInCookie(level: number, paths: Array<PathType>, NHintsUsed: number): void {
    const cookieName = cookieRoot + level;
    const cookieData: CookieData = { paths, NHintsUsed };  // Create a CookieData object

    // Encrypt the cookie data before storing it
    const encryptedCookieValue = encryptData(cookieData);

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);  // Cookie expires in 1 year

    // Set the encrypted cookie with an expiration date
    document.cookie = `${cookieName}=${encryptedCookieValue}; expires=${expiryDate.toUTCString()}; path=/`;
}


export function getPathsFromCookie(level: number): CookieData {
    const cookieName = cookieRoot + level;
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find(c => c.startsWith(cookieName));

    if (cookie) {
        const cookieValue = cookie.split('=')[1];
        try {
            // Decrypt the cookie content first
            const decryptedData = decryptData(cookieValue);

            // Check if the cookie contains both paths and NHintsUsed
            if (decryptedData && Array.isArray(decryptedData.paths) && typeof decryptedData.NHintsUsed === 'number') {
                return {
                    paths: decryptedData.paths,
                    NHintsUsed: decryptedData.NHintsUsed
                };
            }
        } catch (error) {
            console.error('Failed to decrypt or parse cookie data:', error);
        }
    }
    return { paths: [], NHintsUsed: 0 };  // Return default value if cookie doesn't exist or is not in expected format
}


export function getHighestLevelWithCookie(): number | null {
    if (typeof document === 'undefined') {
        // Return null or handle appropriately if document is not available
        return null;
    }

    const cookies = document.cookie.split('; ');

    // Find all cookies that represent levels
    const levelCookies = cookies.filter(cookie => cookie.startsWith(cookieRoot));

    // Extract the level numbers from the cookie names (before the "=")
    const levels = levelCookies.map(cookie => {
        const levelMatch = cookie.match(new RegExp(`^${cookieRoot}(\\d+)=`)); // Match level number before '='
        return levelMatch ? parseInt(levelMatch[1], 10) : null;
    }).filter(level => level !== null) as number[];

    // Return the highest level found or 0 if no levels are present
    return levels.length > 0 ? Math.max(...levels) : null;
}


export function getNHintsUsedFromCookie(level: number): number | null {
    if (typeof document === 'undefined') {
        // Return null or handle appropriately if document is not available
        return null;
    }

    const cookieName = cookieRoot + level;
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find(c => c.startsWith(cookieName));

    if (cookie) {
        const cookieValue = cookie.split('=')[1];
        try {
            // Decrypt the cookie content first
            const decryptedData = decryptData(cookieValue);

            // Check if the cookie contains NHintsUsed
            if (decryptedData && typeof decryptedData.NHintsUsed === 'number') {
                return decryptedData.NHintsUsed;
            }
        } catch (error) {
            console.error('Failed to decrypt or parse cookie data:', error);
        }
    }
    return null;  // Return null if the cookie doesn't exist or NHintsUsed is not found
}


export const validateSolution = (paths: Array<PathType>, pins: Array<PointType>, n_paths: number, debugMode: boolean = false): ValidationResult => {
    // Check if there are paths to check
    if (paths.length === 0) {
        return {
            correctNPaths: false,
            allInSameBranch: null,
            allPinsReached: null
        }
    }

    // Check if the correct number of paths was used
    const correctNPaths = paths.length === n_paths;
    if (!debugMode && !correctNPaths) return {
        correctNPaths,
        allInSameBranch: null,
        allPinsReached: null
    }

    // Create a set of all unique points reached by paths
    const pointsReachedByPaths = new Set<string>();
    for (const path of paths) {
        const [pointA, pointB] = path;
        pointsReachedByPaths.add(JSON.stringify(pointA));
        pointsReachedByPaths.add(JSON.stringify(pointB));
    }

    // Explore paths starting from the first one
    const unexploredPaths: PathType[] = [paths[0]];
    const exploredPaths = new Set<PathType>();

    while (unexploredPaths.length > 0) {
        const exploringPath = unexploredPaths.shift()!;
        exploredPaths.add(exploringPath);
        for (const path of paths) {
            if (exploredPaths.has(path)) continue;

            if (
                JSON.stringify(path[0]) === JSON.stringify(exploringPath[0]) ||
                JSON.stringify(path[1]) === JSON.stringify(exploringPath[0]) ||
                JSON.stringify(path[0]) === JSON.stringify(exploringPath[1]) ||
                JSON.stringify(path[1]) === JSON.stringify(exploringPath[1])
            ) {
                unexploredPaths.push(path);
            }
        }
    }

    // Collect points reached by the connected branch
    const pointsReachedByBranch = new Set<string>();
    for (const path of exploredPaths) {
        const [pointA, pointB] = path;
        pointsReachedByBranch.add(JSON.stringify(pointA));
        pointsReachedByBranch.add(JSON.stringify(pointB));
    }

    // Validate that all points are in the same branch
    const allInSameBranch = pointsReachedByBranch.size === pointsReachedByPaths.size
    if (!debugMode && !allInSameBranch) return {
        correctNPaths,
        allInSameBranch,
        allPinsReached: null
    };

    // Validate that all pins are reached
    for (const pin of pins) {
        if (!pointsReachedByPaths.has(JSON.stringify(pin))) return {
            correctNPaths,
            allInSameBranch,
            allPinsReached: false
        };
    }
    return {correctNPaths, allInSameBranch, allPinsReached: true}; // All checks passed
};