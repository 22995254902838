import React from "react";
import {GameContainer} from "./components";
import Layout from '../../../src/components/layout';
import SEO from '../../../src/components/seo';
import {GameStateProvider} from "./context";
import {ConnectleProps} from "./interfaces";


const ConnectlePage = ({ pageContext }: { pageContext: ConnectleProps })  => {
    const { preview } = pageContext;
    return (
        <Layout do_sticky={false} do_footy={false}>
            <SEO
                title={'Connectle'}
                description={'Play the puzzle game Connectle.'}
                image={preview}
            />
            <GameStateProvider debugMode={false}>
                <GameContainer
                        gridSize={9}
                        pinHoleSize={0.1}
                        pinSize={4}
                        pathHeight={2}
                        pathWidth={0.95}>
                </GameContainer>
            </GameStateProvider>
        </Layout>
    );
};


export default ConnectlePage;


