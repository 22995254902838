import React, {useState, useEffect} from 'react';

import {
    Board,
    StyledPinHole,
    PinHoleRowContainer,
    StyledPin,
    StyledPath,
    StyledTitle,
    ScreenOverlay,
    StyledScoreboard,
    StyledGameContainer
} from "./style";

import {
    GameProps,
    PinHoleRowProps,
    PinHoleProps,
    PathBoxProps,
    PathType,
} from "./interfaces";

import {
    FaBroom,
    FaBackward,
    FaForward,
    FaQuestion,
    FaTrophy,
} from 'react-icons/fa6'

import {usePathContext} from "./context";
import {getNHintsUsedFromCookie} from "./methods";


const PathElement: React.FC<PathBoxProps> = (props) => {
    const {
        nPathsUsed,
        clearPathsTrigger,
        checkOccupationTrigger,
        incrementPathCounter,
        updatePaths,
        n_paths,
        paths,
    } = usePathContext();
    const {rowIndex, colIndex, isUd} = props;

    const path: PathType = [
        [rowIndex, colIndex],
        [rowIndex + (isUd ? 1 : 0), colIndex + (isUd ? 0 : 1)]
    ]

    const [isOccupied, setOccupation] = useState(false);

    const handlePathToggle = () => {
        // Add or remove path from paths
        if (isOccupied || nPathsUsed < n_paths) {
            updatePaths(path)
            setOccupation((prev) => !prev)
            incrementPathCounter(!isOccupied)
        }
    };

    useEffect(() => {
        if (isOccupied) {
            setOccupation(false)
        }
    }, [clearPathsTrigger])

    useEffect(() => {
        const occupation = paths.some(existingPath =>
            JSON.stringify(existingPath) === JSON.stringify(path)
        )
        setOccupation(occupation)
    }, [checkOccupationTrigger])


    return (
        <StyledPath {...props} isOccupied={isOccupied}>
            <div
                className={`path ${nPathsUsed < n_paths || isOccupied ? 'active' : ''}`}
                onClick={handlePathToggle}/>
        </StyledPath>
    )
}

const PinHole: React.FC<PinHoleProps> = (props) => {
    const {hasPin, colIndex, rowIndex, gridSize, hintIndex} = props;
    const {level, highestUnlockedLevel, nHintsUsed, debugMode} = usePathContext();
    return (
        <StyledPinHole {...props}>
            {debugMode && <span>{`${rowIndex},${colIndex}`}</span>}
            {hasPin && <StyledPin
                type={highestUnlockedLevel > level ? 'solved' : 'unsolved'} {...props}/>}
            {hintIndex !== -1 && hintIndex < nHintsUsed &&
                <StyledPin type={'hint'} {...props} />}
            {rowIndex < gridSize && <PathElement isUd={true} {...props}/>}
            {colIndex < gridSize && <PathElement isUd={false} {...props}/>}
        </StyledPinHole>
    );
};

const DebuggingInfo: React.FC = () => {
    const {
        level,
        maxLevel,
        nPathsUsed,
        nHintsUsed,
        clearPathsTrigger,
        checkOccupationTrigger,
        highestUnlockedLevel,
        paths,
        pins,
        helpState,
        scoreboardState,
        n_paths,
        n_hints,
        difficulty,
        hints,
        rightNPaths,
        pathsSameBranch,
        pinsReached,
        validationPassed
    } = usePathContext();

    const renderArray = (array: Array<any>) => array.map(item => JSON.stringify(item)).join(', ');
    return (
        <div className={'debug-info'}>
            <h3>State</h3>
            <div><strong>Level:</strong> {level} / {maxLevel}</div>
            <div><strong>Unlocked:</strong> {highestUnlockedLevel}</div>
            <div><strong>Difficulty:</strong> {difficulty}</div>
            <div><strong>Paths Used:</strong> {nPathsUsed} / {n_paths}</div>
            <div><strong>Hints Used:</strong> {nHintsUsed} / {n_hints}</div>
            <div><strong>Help screen:</strong> {helpState}</div>
            <div><strong>Scoreboard:</strong> {scoreboardState ? 'Visible' : 'Hidden'}</div>
            <h3>Triggers</h3>
            <div><strong>Clear:</strong> {clearPathsTrigger ? 'Enabled' : 'Disabled'}</div>
            <div><strong>Occupation:</strong> {checkOccupationTrigger ? 'Enabled' : 'Disabled'}</div>
            <h3>Completion</h3>
            <div><strong>n Paths:</strong> {String(rightNPaths)}</div>
            <div><strong>Single branch:</strong> {String(pathsSameBranch)}</div>
            <div><strong>Pins reached:</strong> {String(pinsReached)}</div>
            <div><strong>Completed:</strong> {String(validationPassed)}</div>
            <h3>Objects</h3>
            <div><strong>Pins:</strong> {renderArray(pins || [])}</div>
            <div><strong>Paths:</strong> {renderArray(paths || [])}</div>
            <div><strong>Hints:</strong> {renderArray(hints || [])}</div>
        </div>
    );
};

const PinHoleRow: React.FC<PinHoleRowProps> = (props) => {
    const {gridSize, rowIndex} = props;
    const {pins, hints} = usePathContext();
    return (
        <PinHoleRowContainer {...props}>
            {Array.from({length: gridSize + 1}, (_, colIndex) => {
                // Check if the current position (rowIndex, colIndex) is in PinLocations
                const hasPin = pins.some(
                    ([x, y]) => x === rowIndex && y === colIndex
                );
                const hintIndex = hints.findIndex(
                    ([x, y]) => x === rowIndex && y === colIndex
                );
                return (
                    <PinHole
                        key={colIndex}
                        {...props}
                        hasPin={hasPin}
                        hintIndex={hintIndex}
                        colIndex={colIndex}
                    />
                );
            })}
        </PinHoleRowContainer>
    );
};


export const Helper: React.FC = () => {
    const {
        helpState,
        toggleHelpScreen,
        n_hints,
        nHintsUsed,
        addHint
    } = usePathContext();
    const buttonText = helpState === 'new' ? 'Come back here for hints' : `Do you need a${nHintsUsed > 0 ? 'nother' : ''} hint?`
    return (
        <ScreenOverlay visible={helpState !== 'hidden'} onClick={toggleHelpScreen}>
            <div className={'background'}></div>
            <div className={'foreground'}>
                <h1>Connectle</h1>
                <p>{helpState === 'new' && 'Welcome to Connectle.'} Your job is to connect all red pins on the board using paths. But there’s a catch—you have a limited number of paths you can use, so you’ll need to think carefully!</p>
                <p>You can <FaBroom/> clear the board, <FaTrophy/> view your progress on the scoreboard, and <FaQuestion/> get hints if you are stuck.</p>
                {nHintsUsed < n_hints ? (
                    <h3 className={'button-like'} onClick={helpState === 'new' ? undefined : addHint}>{buttonText}</h3>
                ) : (
                    <h3>No more hints available</h3>
                )}
                {helpState === 'new' && (<p className={'fun'}><em>Fun fact: This game is known as the rectilinear steiner tree problem. It is used in the real-world in chip design. This is an NP-Hard problem. So, there is no algorithm known which solves every instance quickly.</em></p>)}
            </div>
        </ScreenOverlay>
    )
}

export const Scoreboard: React.FC = () => {
    const {
        scoreboardState,
        toggleScoreboard,
        maxLevel,
        highestUnlockedLevel,
    } = usePathContext();

    const renderScoreboard = () => {
        const levels = Array.from({length: maxLevel}, (_, i) => i + 1); // Generate level numbers (1 to maxLevel)

        return levels.map((level) => {
            const nHintsUsed = getNHintsUsedFromCookie(level); // Get NHintsUsed for each level
            const isSolved = level < highestUnlockedLevel;
            return (
                <>
                <div key={'level-' + level}>
                    <h2 className={'level'}>{level}.</h2>
                </div>
                <div key={'hints-' + level}>
                    {isSolved && nHintsUsed !== null && <h2 className={nHintsUsed > 0 ? 'red' : 'green'}>{nHintsUsed}</h2> }
                </div>
                </>
            );
        });
    };

    return (
        <ScreenOverlay visible={scoreboardState} onClick={toggleScoreboard}>
            <div className={'background'}></div>
            <div className={'foreground'}>
                <h1>Connectle</h1>
                <StyledScoreboard>
                    {renderScoreboard()}
                </StyledScoreboard>
                <em>Hints used</em>
            </div>
        </ScreenOverlay>
    )
}

export const GameContainer: React.FC<GameProps> = (props) => {
    const {debugMode, helpState, scoreboardState} = usePathContext()
    const {gridSize} = props
    const doBlur = helpState !== 'hidden' || scoreboardState
    return (
        <>
            <StyledGameContainer debugMode={debugMode} doBlur={doBlur}>
                <GameTitle/>
                <Board {...props}>
                    <div className={'grid'}>
                        {Array.from({length: gridSize + 1}, (_, index) => (
                            <PinHoleRow key={index} {...props}
                                        rowIndex={index}/>
                        ))}
                        <div className="square"/>
                    </div>
                </Board>
                {debugMode && <DebuggingInfo/>}

            </StyledGameContainer>
            <Scoreboard/>
            <Helper/>
        </>
    );
};


export const GameTitle = () => {
    const {
        level = ' ',
        maxLevel,
        nPathsUsed,
        nHintsUsed,
        highestUnlockedLevel,
        clearBoard,
        goToNextLevel,
        goToPreviousLevel,
        n_paths,
        difficulty = ' ',
        toggleHelpScreen,
        toggleScoreboard,
    } = usePathContext();

    const remainingPaths = n_paths - nPathsUsed

    return (
        <StyledTitle>
            <div className={'content'}>
                <div>
                    <h1>
                        {`${level}. ${difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}`}
                    </h1>
                </div>
                <div>
                    <h1>
                        <span className={remainingPaths === 0 && highestUnlockedLevel <= level ? 'fat limit' : remainingPaths === 0 ? 'fat success' : 'fat '}>
                            {`${remainingPaths}`}
                        </span>
                        <span>
                            {` path${remainingPaths === 1 ? '' : 's'}    `}
                        </span>
                        <span className={nHintsUsed === 0 ? 'fat success' : 'fat limit'}>
                            {nHintsUsed}
                        </span>
                        <span>
                            {` hint${nHintsUsed === 1 ? '' : 's'}`}
                        </span>
                    </h1>
                </div>
                <div className={'toolbar'}>
                    <h1>
                        <FaBroom onClick={clearBoard}/>
                    </h1>
                    <h1 className={level > 1 ? '' : 'greyedOut'}>
                        <FaBackward onClick={goToPreviousLevel}/>
                    </h1>
                    <h1>
                        <FaQuestion onClick={toggleHelpScreen}/>
                    </h1>
                    {highestUnlockedLevel > level && level < maxLevel ? (
                        <h1 className={'shrink'}>
                            <FaForward onClick={goToNextLevel}/>
                        </h1>
                    ) : (
                        <h1 className={'greyedOut'}>
                            <FaForward/>
                        </h1>
                    )}
                    <h1>
                        <FaTrophy onClick={toggleScoreboard}/>
                    </h1>
                </div>
            </div>
        </StyledTitle>
    );
};



