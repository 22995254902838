import styled from 'styled-components';
import {GameProps, PinHoleProps, PathProps, PinProps, HelperProps} from "./interfaces";

// # Colors
// path_blue = #272a37
// tile_dark = #6F5124
// tile_light = #cd913
//pin_red = #ca2801


export const StyledGameContainer = styled.div<{ debugMode: boolean, doBlur: boolean }>`
  filter: ${(p) => p.doBlur ? 'blur(2px)' : 'none'};
  height: ${(p) => p.debugMode ? 'auto' : '100vh'};
  overflow: ${(p) => p.debugMode ? 'auto' : 'hidden'};
  .debug-info {
    display: ${(p) => p.debugMode ? 'block' : 'none'};
    width: 100%;
    h3 {
      margin: 1em 0 0 0;
    }
  }
`

export const StyledScoreboard = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @media (min-width: 640px) {
      grid-template-columns: repeat(12, 1fr);
    }
    gap: 10px;

    @media (max-width: 639px) {
      h2 {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    h2.level {
      font-weight: normal;
    }
    
    h2.green {
      color: #14a208;
    }

    h2.red {
      color: #ca2801;
    }
`;


export const ScreenOverlay = styled.div<HelperProps>`
    display: ${(p) => p.visible ? 'block' : 'none'};
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    //align-content: center;
    text-align: center;
  p {
    max-width: 600px;
    margin: 1em auto;
  }
  h3.button-like {
    border: 1px solid ${({theme}) => theme.text};
    width: fit-content;
    margin: 1em auto;
    padding: 0 10px;
    border-radius: 10px;
    &:hover {
      color: #ca2801;
    }
  }
  div.foreground {
    text-shadow: 0 0 10px ${({theme}) => theme.body};
    position: relative;
    z-index: 5;
    
    background: ${({theme}) => theme.body};
    box-shadow: 0 0 15px rgba(0, 0, 0, 50%);
    border-radius: 10px;
    
    padding: 40px;
    margin: 40px auto;
    @media (max-width: 540px) {
      padding: 40px 20px;
      margin: 40px 20px;
    }
    @media (max-width: 450px) {
      padding: 40px 5px;
      margin: 40px 10px;
    }
    max-width: 500px;
  }
  div.background {
    z-index: 4;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 380px) {
      p.fun {
      display:none;
    }
  }
`


export const Board = styled.div<GameProps>`
  width: 100%;
  display: flex;
  justify-content: center;

  .square {
    padding-top: 100%;
  }
  
  div.grid {
      // The minimal padding of 15px is the reduction of padding around the pins 
      // adjusted for the margin (20px). A perfect match (padding = distance 
      // between pins) would be margin / 2 = 10px. However, a higher reduction
      // (15px > 10px) gives users on small screens more space to work with.
      padding: ${(p) => 1 / (5 * p.gridSize) * 100}%;
      width: 100%;
      max-width: 600px;
      border-radius: 5%;
      position: relative;
      margin: 20px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 30%);
      @media (min-width: 360px) {
            box-shadow: 8px 8px 20px rgba(0, 0, 0, 30%);
      }
      @media (min-width: 600px) {
            box-shadow: 10px 10px 40px rgba(0, 0, 0, 30%);
      }

      background: linear-gradient(180deg, rgba(255, 255, 255, 30%), rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0) 97%, rgba(0, 0, 0, 30%)),
      linear-gradient(90deg, rgba(255, 255, 255, 30%), rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 0) 97%, rgba(0, 0, 0, 30%)),
      linear-gradient(#cd913b, #cd913b);
  }
`

export const StyledTitle = styled.div`
  width: 100%;

  text-align: center;
  margin-top: 1em;

  div.content {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }

  h1 {
    font-weight: normal;
    span.fat {
      font-weight: bold;
    }
  } 
  
  
  @media (max-width: 600px) {
    span.verbose {
      display: none;
    }
  }


  span.limit {
    color: #ca2801; // red
  }

  span.success {
    color: #14a208; // green
  }
  h1:not(.greyedOut) {
    @media (hover: hover) {
      svg:hover {
        fill: #ca2801; // red
      }
    }
  }

  .toolbar {
    display: flex;
    justify-content: space-around;
  }

  h1.greyedOut {
    opacity: 20%;
  }
`

export const StyledPinHole = styled.div<PinHoleProps>`
  span {
    top: 0.5em;
    left: 0.5em;
    position: absolute;
    font-size: 14px;
    z-index: 4;
    color: black;
  }
  background: radial-gradient(ellipse at right bottom, black, #6F5124);
  border-radius: 100%;
  width: 100%;
  height: ${(p) => p.pinHoleSize * 100}%;
  position: relative;
`

export const StyledPin = styled.div<PinProps>`
  position: absolute;
    // top: ${(p) => (-(p.pinSize / p.pinHoleSize) + p.pinHoleSize * (p.pinSize / p.pinHoleSize) / 2) * 100}%;
  left: ${(p) => -(p.pinSize * 100) / 2 + 50}%;
  top: ${(p) => -(p.pinSize * 100) / 2 + 50}%;
    // left: ${(p) => (-(p.pinSize / p.pinHoleSize) + p.pinHoleSize * (p.pinSize / p.pinHoleSize) / 2) * 100}%;

  //background: radial-gradient(ellipse at left top, );
  background: radial-gradient(ellipse at left top, ${(p) =>
          p.type === 'solved'   ? '#73D06A, #14a208, #7a170a' : // greens
          p.type === 'unsolved' ? '#da6e54, #ca2801, #7a170a' : // reds
          p.type === 'hint'     ? '#dacb54, #cacb01, #7a6e0a' : // yellows
          '#000, #fff'});                                       // fallback
  border-radius: 100%;
  width: ${(p) => p.pinSize * 100}%;
  height: ${(p) => p.pinSize * 100}%;
  z-index: 2;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 30%);
  border: 1px solid black;
`

export const StyledPath = styled.div<PathProps>`
  position: absolute;
  width: ${(p) => p.pathWidth * 100}%;
  height: ${(p) => p.pathHeight * 100}%;
  left: ${(p) => p.isUd ? 0 : (1 - p.pinHoleSize) / p.pinHoleSize * (1 - p.pathWidth) / 2 * 100}%;
  top: ${(p) => p.isUd ? (1 - p.pinHoleSize) / p.pinHoleSize * (1 - p.pathWidth) / 2 * 100 - (p.pathHeight - 1) / 2 * 100 : (1 - p.pathHeight) / 2 * 100}%;

  rotate: ${(p) => (p.isUd ? 90 : 0)}deg;
  //transform: translateX(10%);
  .path {
    position: absolute;
    left: 100%;
    border-radius: 999px; // Ensure max roundness
    z-index: 1;
    height: 100%;
    width: ${(p) => ((1 - p.pinHoleSize) / p.pinHoleSize) * 100}%;
    background: // Long side
            linear-gradient(${(p) => p.isOccupied ? (1 - +p.isUd) * 180 : +p.isUd * 180}deg, rgba(0, 0, 0, 25%), rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 50%)),
              // Short side
            linear-gradient(${(p) => p.isOccupied ? 90 : -90}deg, rgba(0, 0, 0, 25%), rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 50%)),
              //Background
            linear-gradient(${(p) => p.isOccupied ? '#272a37, #272a37' : '#ad7b33, #ad7b33'});

    &.active:hover {
      box-shadow: 0 0 0 5px #272a37;
      cursor: pointer;
    }
  }
`;

export const PinHoleRowContainer = styled.div<GameProps>`
  width: 100%;
  height: ${(p) => 100 / (p.pinHoleSize * (p.gridSize + 1) + (1 - p.pinHoleSize) * p.gridSize)}%;
  display: flex;
  justify-content: flex-start; /* Align horizontally */
  gap: ${(p) => 100 / (p.gridSize * (1 - p.pinHoleSize) + (p.gridSize + 1) * p.pinHoleSize) * (1 - p.pinHoleSize)}%;
`;

