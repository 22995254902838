import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    useRef,
} from "react";

import {
    getHighestLevelWithCookie,
    storePathsInCookie,
    getPathsFromCookie,
    doesCookieExist,
    validateSolution
} from "./methods";

import {
    LevelProps,
    PathType,
    GameStateProviderProps,
    HelpScreenStates
} from "./interfaces";

const PathContext = createContext<{
    level: number;
    maxLevel: number;
    nPathsUsed: number;
    nHintsUsed: number;
    clearPathsTrigger: boolean;
    checkOccupationTrigger: boolean;
    highestUnlockedLevel: number;
    rightNPaths: boolean;
    pathsSameBranch: boolean | null;
    pinsReached: boolean | null;
    validationPassed: boolean;
    paths: Array<PathType>;
    updatePaths: (path: PathType) => void;
    incrementPathCounter: (isAdding: boolean) => void;
    clearBoard: () => void;
    goToNextLevel: () => void;
    goToPreviousLevel: () => void;
    helpState: HelpScreenStates;
    scoreboardState: boolean;
    toggleHelpScreen: () => void;
    toggleScoreboard: () => void;
    addHint: () => void;
    debugMode: boolean;
} & LevelProps | null>(null);

export const usePathContext = () => {
    const context = useContext(PathContext);
    if (!context) {
        throw new Error("usePathContext must be used within a PathProvider");
    }
    return context;
};

const maxLevel: number = 30;


const defaultLevelProperties: LevelProps = {
    difficulty: '',
    hints: [],
    n_hints: 0,
    n_paths: 0,
    n_pins: 0,
    pins: [],
    size: 9,
}


export const GameStateProvider: React.FC<GameStateProviderProps> = ({ debugMode, children }) => {
    const [levelProperties, setLevelProperties] = useState<LevelProps>(defaultLevelProperties);
    const [highestUnlockedLevel, setHighestUnlockedLevel] = useState<number>(1)
    const [nPathsUsed, setNPathsUsed] = useState<number>(0);
    const [nHintsUsed, setNHintsUsed] = useState<number>(0);
    const [clearPathsTrigger, setClearPathsTrigger] = useState<boolean>(false);
    const [checkOccupationTrigger, setCheckOccupationTrigger] = useState<boolean>(false);
    const [paths, setPaths] = useState<Array<PathType>>([]);
    const level = useRef<number>(1);
    const initialRender = useRef<boolean>(true)
    const {pins, n_paths, n_hints} = levelProperties || {};
    const [helpState, setHelpState] = useState<HelpScreenStates>('hidden')
    const [scoreboardState, setScoreboardState] = useState<boolean>(false)
    const [rightNPaths, setRightNPaths] = useState<boolean>(false)
    const [pathsSameBranch, setPathsSameBranch] = useState<boolean | null>(null)
    const [pinsReached, setPinsReached] = useState<boolean | null>(null)
    const [validationPassed, setValidationPassed] = useState<boolean>(false)

    const addHint = () => {
        if (nHintsUsed <= n_hints) {
            storePathsInCookie(level.current, paths, nHintsUsed + 1)
            setNHintsUsed(prev => prev + 1)
        }
    }
    const toggleHelpScreen = () => {
        setHelpState((prev) => prev === 'hidden' ? 'visible' : 'hidden')
    }
    const toggleScoreboard = () => {
        setScoreboardState((prev) => !prev)
    }

    const goToLevel = (levelNumber: number) => {
        // Retrieve server and client side level information
        const data: LevelProps = require(`../cases/${levelNumber}.json`);
        const {paths: levelPaths, NHintsUsed} = getPathsFromCookie(levelNumber)
        // Set references
        setPaths(levelPaths)
        level.current = levelNumber;
        // Set states
        setCheckOccupationTrigger((prev) => !prev)
        setNHintsUsed(NHintsUsed)
        setLevelProperties(data)
        setNPathsUsed(levelPaths.length)
    }

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            const highestLevel = getHighestLevelWithCookie();
            if (highestLevel == null) {
                setHelpState('new')
                goToLevel(1)
            } else {
                goToLevel(highestLevel)
                setHighestUnlockedLevel(highestLevel)
            }
        }
    }, [])

    const incrementPathCounter = (isAdding: boolean) => {
        setNPathsUsed((prev) => prev + (isAdding ? 1 : -1))
        if (level.current == highestUnlockedLevel) {
            storePathsInCookie(level.current, paths, nHintsUsed)
        }
    };


    useEffect(() => {
        const validationResult = validateSolution(paths, pins, n_paths, debugMode)
        const {correctNPaths, allInSameBranch, allPinsReached} = validationResult
        const passed = Object.values(validationResult).every((value) => value === true);
        setRightNPaths(correctNPaths)
        setPathsSameBranch(allInSameBranch)
        setPinsReached(allPinsReached)
        setValidationPassed(passed)
        if (passed) {
            storePathsInCookie(level.current, paths, nHintsUsed)
            setHighestUnlockedLevel(Math.max(highestUnlockedLevel, level.current + 1))
            if (!doesCookieExist(level.current + 1)) {
                storePathsInCookie(level.current + 1, [], 0)
            }
        }
    }, [paths])




    const clearBoard = () => {
        setNPathsUsed(0)
        setPaths([])
        setClearPathsTrigger((prev) => !prev)
    }

    const goToNextLevel = () => {
        if (level.current === maxLevel) {
            return
        }
        goToLevel(level.current + 1)
    }

    const goToPreviousLevel = () => {
        if (level.current === 1) {
            return
        }
        goToLevel(level.current - 1)
    }

    const updatePaths = (path: PathType) => {
        setPaths((prevPaths) =>
            prevPaths.some((p) => JSON.stringify(p) === JSON.stringify(path))
                ? prevPaths.filter((p) => JSON.stringify(p) !== JSON.stringify(path)) // Remove the path
                : [...prevPaths, path] // Add the path
        );
    };

    return (
        <PathContext.Provider
            value={{
                level: level.current,
                maxLevel,
                nPathsUsed,
                nHintsUsed,
                clearPathsTrigger,
                checkOccupationTrigger,
                highestUnlockedLevel,
                paths: paths,
                rightNPaths,
                pathsSameBranch,
                pinsReached,
                validationPassed,
                updatePaths,
                incrementPathCounter,
                clearBoard,
                goToNextLevel,
                goToPreviousLevel,
                helpState,
                scoreboardState,
                toggleHelpScreen,
                toggleScoreboard,
                addHint,
                debugMode,
                ...levelProperties,
            }}
        >
            {children}
        </PathContext.Provider>
    );
};